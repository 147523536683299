module.exports = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = window.CONFIG.federatedModules['components'];
    script.defer = true;
    script.onerror = reject;
    script.onload = () => {
      const proxy = {
        get: (request) => window.ConfirmitComponents.get(request),
        init: (arg) => {
          try {
            return window.ConfirmitComponents.init(arg)
          } catch(e) {
            console.log('remote container already initialized')
          }
        }
      }
      resolve(proxy)
    };

    document.head.appendChild(script);
  });
;